import { createSlice } from "@reduxjs/toolkit";
const categorySlice = createSlice({
  name: "category",
  initialState: {
    data: {},
    isLoading: false,
  },
  reducers: {
    startcategoryOperation: (state) => ({ ...state, isLoading: true }),
    getCategoty: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }),
  },
});

export const { startcategoryOperation, getCategoty } = categorySlice.actions;
export default categorySlice.reducer;
