import { createSlice } from "@reduxjs/toolkit";
const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {},
    isLoading: false,
    error: false,
  },
  reducers: {
    startUserOperation: (state) => ({ ...state, isLoading: true }),
    createUser: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }),
    loginUser: (state, action) => {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        error: action.payload.error,
      };
    },
    clearUserState(state, action) {
      state.isLoading = false;
      state.data = "";
      state.profileImage = "";
    },
  },
});

export const { startUserOperation, createUser, loginUser, clearUserState } =
  userSlice.actions;
export default userSlice.reducer;
