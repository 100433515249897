import React from "react";
import { Navigate } from "react-router-dom";
import { checkCookie } from "../utils/cookies";

import AdminLeftPanel from "../components/admin/adminLeftPanel/adminLeftPanel";

const PrivateRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = checkCookie();
  return isAuthenticated ? (
    <>
      <AdminLeftPanel /> <Component {...rest} />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
