import { createSlice } from "@reduxjs/toolkit";
const itemSlice = createSlice({
  name: "item",
  initialState: {
    data: {},
    isLoading: false,
  },
  reducers: {
    startItemOperation: (state) => ({ ...state, isLoading: true }),
    getItem: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }),
  },
});

export const { startItemOperation, getItem } = itemSlice.actions;
export default itemSlice.reducer;
