export const EDIT_USER = 'EDIT_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const GET_PROFILE_IMAGE = 'GET_PROFILE_IMAGE';
export const GET_PROFILE_IMAGE_SUCCESS = 'GET_PROFILE_IMAGE_SUCCESS';
export const GET_PROFILE_IMAGE_ERROR = 'GET_PROFILE_IMAGE_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_CATEGORYLIST = 'GET_CATEGORYLIST';
export const GET_ITEMLIST = 'GET_ITEMLIST';
