//import axios from "axios";
import axiosHandler from "./intercepter";

// Define variables to be used for Postman Mocks
//
// const POSTMAN_MOCK_URL = 'https://dbf75e03-2a8b-4137-98bf-56d09a516322.mock.pstmn.io/'
//
// const POSTMAN_MOCK_SECURITY_KEY = 'your_security_key';
// POSTMAN_API_ENDPOINTS_TO_MOCK needs an array of endpoint objects, each containing a "url" and "method" that you want to mock.
// If the array is empty or not defined, the mock will not be active
// const POSTMAN_API_ENDPOINTS_TO_MOCK = [
//  {url: 'documents/14355852', method: 'GET'}
// ]

//Axios will use this in front of all api calls

export const setAuthToken = (token) => {
  axiosHandler.defaults.headers.common["X-Auth-Token"] = token;
};

export const get = async (url, config) => {
  return (await axiosHandler.get(url, config)).data;
};

export const getBlob = async (url, config = {}) => {
  config.responseType = "blob";
  return await axiosHandler.get(url, config);
};
export const post = async (url, data) => {
  return (await axiosHandler.post(url, data)).data;
};

export const postFile = async (url, data) => {
  return (
    await axiosHandler.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  ).data;
};

export const put = async (url, data) => {
  return (await axiosHandler.put(url, data)).data;
};

export const deleteRec = async (url) => {
  return (await axiosHandler.delete(url)).data;
};

export const deleteRecWithData = async (url, data) => {
  return (await axiosHandler({ method: "delete", url: url, data: data })).data;
};

export const generateApiParameters = (pagination, filters, sorter) => {
  let params = {
    limit: pagination.pageSize,
    offset: (pagination.current - 1) * pagination.pageSize,
    sort:
      sorter.field &&
      `${sorter.order === "descend" ? "-" : "+"}${sorter.field}`,
  };
  Object.keys(filters).forEach((item) => {
    if (filters[item]) {
      params[item] = filters[item][0];
    }
  });
  return params;
};

// Interceptor Functions

export const passThroughResponse = (response) => response;

export const handleRequestError = (error) => {
  return Promise.reject(error);
};

// This will handle API errors that return an error in the format:
//   {"errors":[{"code":"031", "message":"Requested animal or agent not found"}]}
// It will only show the first error message in the errors array, using AntD message.error
// export const handleSimpleApiError = (error, intl) => {
//   const {response} = error;
//   if (!response) {
//     console.error(error);
//     message.error(intl.formatMessage({id: 'server.error'}));
//   } else {
//     if (response.data && response.data.errors) {
//       message.error(response.data.errors[0].message);
//     } else {
//       console.error(error);
//       message.error(intl.formatMessage({id: 'server.error'}));
//     }
//   }
// };
