import { put, call } from "redux-saga/effects";
import * as api from "../utils/api";
import { startItemOperation, getItem } from "../reducers/item";

export function* getItemSaga(payload) {
  try {
    yield put(startItemOperation());
    const response = yield call(
      api.post,
      "/userForAdmin/getDataForAdminWithSortByGeometryAndCreatedDate",
      payload.payload
    );
    yield put(getItem(response));
  } catch (error) {
    if (error.response) {
      yield put(getItem(error.response.data));
    } else {
      yield put(getItem({ response: { error: "Error" } }));
    }
  }
}
