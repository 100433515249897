import { takeLatest } from "redux-saga/effects";
import { editUserSaga, loginSaga } from "./authenticationSaga";
import { getCategory } from "./category";
import { getItemSaga } from "./item";
import * as types from "../actions";

export default function* watchUserAuthentication() {
  yield takeLatest(types.EDIT_USER, editUserSaga);
  yield takeLatest(types.LOGIN_USER, loginSaga);
  yield takeLatest(types.GET_CATEGORYLIST, getCategory);
  yield takeLatest(types.GET_ITEMLIST, getItemSaga);
}
