import { put, call } from "redux-saga/effects";
import * as api from "../utils/api";
import { startcategoryOperation, getCategoty } from "../reducers/getCategory";

export function* getCategory(payload) {
  try {
    yield put(startcategoryOperation());
    const response = yield call(
      api.get,
      "/common/getAllCategory",
      payload.user
    );
    yield put(getCategoty(response));
  } catch (error) {
    if (error.response) {
      yield put(getCategoty(error.response.data));
    } else {
      yield put(getCategoty({ response: { error: "Error" } }));
    }
  }
}
