import userReducer from "./userReducer";
import getCategory from "./getCategory";
import getItem from "./item";
import { combineReducers } from "redux";
const rootReducer = combineReducers({
  userReducer,
  getCategory,
  getItem,
});

export default rootReducer;
