import { put, call } from "redux-saga/effects";
import * as api from "../utils/api";
import {
  createUser,
  startUserOperation,
  loginUser,
} from "../reducers/userReducer";

export function* editUserSaga(payload) {
  try {
    yield put(startUserOperation());
    const response = yield call(api.post, "/user", payload.user);
    yield put(createUser(response));
  } catch (error) {
    if (error.response) {
      yield put(createUser(error.response.data));
    } else {
      yield put(createUser({ response: { error: "Error" } }));
    }
  }
}

export function* loginSaga(payload) {
  try {
    yield put(startUserOperation());
    let response = yield call(api.post, "/admin/adminLogin", payload.user);
    response.status = 200;
    const data = {
      data: response,
      error: false,
    };
    yield put(loginUser(data));
  } catch (error) {
    const data = {
      err: error,
      error: true,
    };
    yield put(loginUser(data));
  }
}
