import { css } from '@emotion/react';

const styles = {
    drawer: css`
        width: 240px;
        flex-shrink: 0;
    `,
    drawerPaper: css`
        width: 240px;
    `,
    drawerContainer: css`
        overflow: 'auto',
    `,
    menu: css`
        padding: 0;
    `,
    submenu: css`
        padding: 0;
        margin-left: 10px;
    `,
    
    settingMenus: css`
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    `,
    
};

export default styles;