const MENU_ITEMS = [
  {
    id: 1,
    title: "Master Setting",
  },
  {
    id: 2,
    title: "Admin User",
  },
  {
    id: 3,
    title: "User List",
  },
  {
    id: 3,
    title: "Item",
  },
  {
    id: 4,
    title: "Advertise",
  },
];

//"Submenu 1", "Submenu 2", "Submenu 3"
const SUBMENU_USER = [
  {
    id: 1,
    title: "Admin User",
    path: "/adminUser",
  },
  {
    id: 2,
    title: "Add Admin User",
    path: "/addUser",
  },
];
const SUBMENU_MASTERSETTING = [
  {
    id: 1,
    title: "Category",
    path: "/categoryList",
  },
  {
    id: 2,
    title: "Add Category",
    path: "/addCategory",
  },
  {
    id: 3,
    title: "Upload default image",
    path: "/defaultImage",
  },
  {
    id: 4,
    title: "Add Constant",
    path: "/addConstant",
  },
  {
    id: 5,
    title: "Constant List",
    path: "/constantList",
  },
];

const SUBMENU_ITEM = [
  {
    id: 1,
    title: "Item List",
    path: "/itemList",
  },
];

const SUBMENU_USER_LIST = [
  {
    id: 1,
    title: "User List",
    path: "/userList",
  },
];

export {
  MENU_ITEMS,
  SUBMENU_USER,
  SUBMENU_MASTERSETTING,
  SUBMENU_ITEM,
  SUBMENU_USER_LIST,
};
